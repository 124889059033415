<template>
  <AirCNavBarWrapper v-if="hasSiteLayoutLoaded && showAirCNavBar" />
  <div class="app-wrapper">
    <template v-if="hasSiteLayoutLoaded">
      <template v-if="authorized">
        <NavBar v-if="!isNavbarHidden" ref="navBar" />
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </template>
      <vue-401 v-else />
      <Teleport to="body">
        <SnackBar ref="snackBar" />
      </Teleport>
    </template>
  </div>
  <div id="tooltip-wrapper" />
  <div id="modal-wrapper">
    <CustomerContractsModal ref="customerContractsModal" />
    <IFSModal v-if="!isMobile" ref="ifsModal" />
    <DeliveryCostInformationModal ref="deliveryCostInformationModal" />
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';

import { useUserStore } from '@/store/userStore';
import { ROUTE_NAMES } from '@/router';
import { AwsRum } from 'aws-rum-web';
import Vue401 from '@/views/Vue401.vue';

import NavBar from '@/components/NavBar/NavBar.vue';
import SnackBar from '@/components/shared/SnackBar/SnackBar.vue';
import CustomerContractsModal from '@/components/Customers/CustomerContracts/CustomerContractsModal.vue';

import IFSModal from '@/components/shared/Modals/IFSModal.vue';
import DeliveryCostInformationModal from './components/shared/Modals/DeliveryCostInformationModal.vue';
import AirCNavBarWrapper from './components/AirCNavBar/AirCNavBarWrapper.vue';

export default {
  name: 'App',
  components: {
    AirCNavBarWrapper,
    NavBar,
    Vue401,
    SnackBar,
    CustomerContractsModal,
    IFSModal,
    DeliveryCostInformationModal,
  },
  inject: ['isMobile', 'isTablet'],
  provide() {
    return {
      getNavSearchBar: () => this.$refs.navBar?.$refs?.searchBar,
      showSnackBar: (m) => this.$refs.snackBar.showMessage(m),
      getNavBar: () => this.$refs.navBar,
      isLocal: computed(() => this.isLocal),
      isDev: computed(() => this.isDev),
      isTest: computed(() => this.isTest),
      isProd: computed(() => this.isProd),
      isDashboard: computed(() => this.isDashboard),
      isMonitoredOrdersTab: computed(() => this.isMonitoredOrdersTab),
      isFlaggedOrdersTab: computed(() => this.isFlaggedOrdersTab),
      isArchivedOrdersTab: computed(() => this.isArchivedOrdersTab),
      openCustomerContractsModal: (contracts) =>
        this.$refs.customerContractsModal.open(contracts),
      openIFSModal: (order) => this.$refs.ifsModal.open(order),
      openDeliveryCostInformationModal: (showTruckDeliveryCost) => {
        this.$refs.deliveryCostInformationModal.open(showTruckDeliveryCost);
      },
    };
  },
  data() {
    return {
      showAirCNavBar: false,
      authorized: false,
      hasSiteLayoutLoaded: false,
      sidebarClosedClass: 'sidebar-closed',
      isNavbarHidden: false,
    };
  },
  computed: {
    isDashboard() {
      const currentRoute = this.$route.name;

      return currentRoute === ROUTE_NAMES.DASHBOARD;
    },
    isLocal() {
      return process.env.VUE_APP_STAGE === 'local';
    },
    isDev() {
      return process.env.VUE_APP_STAGE === 'dev';
    },
    isTest() {
      return process.env.VUE_APP_STAGE === 'test';
    },
    isProd() {
      return process.env.VUE_APP_STAGE === 'prod';
    },
    isMonitoredOrdersTab() {
      const currentRoute = this.$route.name;
      return currentRoute === ROUTE_NAMES.MONITORED;
    },
    isFlaggedOrdersTab() {
      const currentRoute = this.$route.name;
      return currentRoute === ROUTE_NAMES.FLAGGED;
    },
    isArchivedOrdersTab() {
      const currentRoute = this.$route.name;
      return currentRoute === ROUTE_NAMES.ARCHIVED;
    },
  },
  watch: {
    isMobile() {
      this.changeBodyBg();
      this.handleSidebarToggle();
      this.fixSideNavMinHeight();
    },
    isTablet() {
      this.fixSideNavMinHeight();
    },
    isDashboard() {
      const body = document.querySelector('body');

      const dashboardClass = 'is-dashboard';
      if (this.isDashboard) {
        body.classList.add(dashboardClass);
      } else {
        body.classList.remove(dashboardClass);
      }
    },

    $route() {
      this.isNavbarHidden = this.$route.path.includes('/customers');
    },
  },
  created() {
    if (document.body.classList.contains('airc-navbar')) {
      this.showAirCNavBar = true;
    }

    localStorage.removeItem('monitoredOrders'); // Moving to cloud stored monitor

    const siteLayoutElement = document.querySelector('mymunters-site-layout');
    if (siteLayoutElement?.hasLoaded) {
      this.onSiteLoaded();
    } else {
      document.addEventListener('mmSiteLayoutLoaded', async () => {
        this.onSiteLoaded();
      });
    }
  },
  beforeMount() {
    this.addCloudWatchRum();
  },
  methods: {
    async onSiteLoaded() {
      if (this.showAirCNavBar) {
        let hamburger =
          window.mmTopbar?.shadowRoot?.querySelector('.hamburger');
        hamburger.style.display = 'none';

        let logoContainer = window.mmTopbar?.shadowRoot?.querySelector(
          '.logo-label-container'
        );
        logoContainer.style.display = 'none';
      }

      const currentPathName = new URL(window.location.href).pathname;
      this.isNavbarHidden = currentPathName.includes('/customers');

      await this.checkAuth();

      const sideMenu = document.querySelector('mymunters-navigation-menu');
      sideMenu.style.zIndex = 1000;

      const mmTopbar = document.querySelector('mymunters-topbar');
      mmTopbar.label = 'ETA';
      mmTopbar.style.zIndex = 1001;
      const userStore = useUserStore();
      await userStore.setUser();

      this.authorized = userStore.isAuthorized;

      const external = userStore.isExternal;

      if (external) this.handleCustomerAccess();

      this.changeBodyBg();

      this.hasSiteLayoutLoaded = true;

      this.listenToSidebarToggle();
      this.fixSideNavMinHeight();
    },
    async checkAuth() {
      const mmTopbar = document.querySelector('mymunters-topbar');
      const authorized = await mmTopbar?.isLoggedIn();
      if (!authorized) {
        await mmTopbar.login();
      }
      await mmTopbar.statisticsTrigger();
    },

    changeBodyBg() {
      if (this.isMobile) {
        document.body.className += ' mobile';
      } else {
        document.body.className = document.body.className.replace('mobile', '');
      }
    },
    handleCustomerAccess() {
      const routes = this.$router.getRoutes();
      const defaultRoute = routes.find((route) => route.path === '/');
      defaultRoute.redirect = '/orders';

      this.$router.removeRoute('Dashboard');
    },
    addCloudWatchRum() {
      try {
        if (
          !process.env.VUE_APP_CLOUDWATCH_RUM_APPLICATION_ID ||
          !process.env.VUE_APP_CLOUDWATCH_RUM_IDENTITY_POOL_ID
        ) {
          throw new Error('Missing required CloudWatch RUM configuration');
        }
        const config = {
          sessionSampleRate: 1,
          identityPoolId: process.env.VUE_APP_CLOUDWATCH_RUM_IDENTITY_POOL_ID,
          endpoint: 'https://dataplane.rum.eu-north-1.amazonaws.com',
          telemetries: ['performance', 'errors', 'http'],
          allowCookies: false,
          enableXRay: false,
        };

        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'eu-north-1';

        new AwsRum(
          process.env.VUE_APP_CLOUDWATCH_RUM_APPLICATION_ID,
          APPLICATION_VERSION,
          APPLICATION_REGION,
          config
        );
      } catch (error) {
        console.error('Failed to initialize CloudWatch RUM:', error);
      }
    },
    listenToSidebarToggle() {
      const topbar = document.querySelector('mymunters-topbar');
      const toggleControl = topbar.shadowRoot?.querySelector('img.hamburger');

      toggleControl.addEventListener('click', () => this.handleSidebarToggle());
    },
    handleSidebarToggle() {
      if (this.isMobile) {
        document.body.classList.remove(this.sidebarClosedClass);
        return;
      }

      const sidebarOuterContainer = this.getSidebarOuterContainer();

      const isSidebarClosed = sidebarOuterContainer?.classList?.contains(
        'non-mobile-collapsed'
      );

      if (isSidebarClosed) {
        document.body.classList.add(this.sidebarClosedClass);
      } else {
        document.body.classList.remove(this.sidebarClosedClass);
      }
    },
    fixSideNavMinHeight() {
      const outerDiv = this.getSidebarOuterContainer();

      if (!outerDiv) return;

      if (this.isMobile || this.isTablet) {
        outerDiv.style.minHeight = 'unset';
        return;
      }

      outerDiv.style.minHeight = 'calc(100svh - 56px)';
    },
    getSidebarOuterContainer() {
      const sidebar = document.querySelector('mymunters-navigation-menu');

      return sidebar?.shadowRoot?.querySelector('div.outer');
    },
  },
};
</script>

<style lang="scss" scoped>
#tooltip-wrapper {
  position: fixed;
  z-index: 99999;
}

#modal-wrapper {
  position: fixed;
  z-index: 9999;
}
</style>

<style lang="scss">
@import './styles/main';

body {
  background-color: rgb(234, 241, 244);

  &.mobile {
    background-color: #fff;
  }
}

@media screen and (max-width: $bp-xs) {
  body {
    width: 100%;
  }
}
</style>
